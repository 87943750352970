import { useChatToggle } from "@livekit/components-react";
import * as React from "react";

/** @public */
export interface ChatToggleProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {}

/**
 * The `ChatToggle` component is a button that toggles the visibility of the `Chat` component.
 * @remarks
 * For the component to have any effect it has to live inside a `LayoutContext` context.
 *
 * @example
 * ```tsx
 * <LiveKitRoom>
 *   <ToggleChat />
 * </LiveKitRoom>
 * ```
 * @public
 */
export const ChatButton: (
  props: ChatToggleProps & React.RefAttributes<HTMLButtonElement>
) => React.ReactNode = /* @__PURE__ */ React.forwardRef<
  HTMLButtonElement,
  ChatToggleProps
>(function ChatButton(props: ChatToggleProps, ref) {
  const { mergedProps } = useChatToggle({ props });

  return (
    <button ref={ref} {...mergedProps} className="bg-[#8C7E67] text-[#FFFFFF] h-14 w-full rounded-xl grid place-items-center">
      {props.children}
    </button>
  );
});
