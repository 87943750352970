import { computeMenuPosition, wasClickOutside } from "@livekit/components-core";
import * as React from "react";
import { log } from "@livekit/components-core";
import type { LocalAudioTrack, LocalVideoTrack } from "livekit-client";
import { MediaDeviceSelect } from "./MediaDeviceSelect";

/** @public */
export interface MediaDeviceMenuProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  kind?: MediaDeviceKind;
  initialSelection?: string;
  onActiveDeviceChange?: (kind: MediaDeviceKind, deviceId: string) => void;
  tracks?: Partial<
    Record<MediaDeviceKind, LocalAudioTrack | LocalVideoTrack | undefined>
  >;
  /**
   * this will call getUserMedia if the permissions are not yet given to enumerate the devices with device labels.
   * in some browsers multiple calls to getUserMedia result in multiple permission prompts.
   * It's generally advised only flip this to true, once a (preview) track has been acquired successfully with the
   * appropriate permissions.
   *
   * @see {@link PreJoin}
   * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/enumerateDevices | MDN enumerateDevices}
   */
  requestPermissions?: boolean;
}

/**
 * The `MediaDeviceMenu` component is a button that opens a menu that lists
 * all media devices and allows the user to select them.
 *
 * @remarks
 * This component is implemented with the `MediaDeviceSelect` LiveKit components.
 *
 * @example
 * ```tsx
 * <LiveKitRoom>
 *   <MediaDeviceMenu />
 * </LiveKitRoom>
 * ```
 * @public
 */
export function MediaDeviceMenu({
  kind,
  initialSelection,
  onActiveDeviceChange,
  tracks,
  requestPermissions = false,
  ...props
}: MediaDeviceMenuProps) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [devices, setDevices] = React.useState<MediaDeviceInfo[]>([]);
  const [updateRequired, setUpdateRequired] = React.useState<boolean>(true);
  const [needPermissions, setNeedPermissions] =
    React.useState(requestPermissions);

  const handleActiveDeviceChange = (
    kind: MediaDeviceKind,
    deviceId: string
  ) => {
    log.debug("handle device change");
    setIsOpen(false);
    onActiveDeviceChange?.(kind, deviceId);
  };

  const button = React.useRef<HTMLButtonElement>(null);
  const tooltip = React.useRef<HTMLDivElement>(null);

  React.useLayoutEffect(() => {
    if (isOpen) {
      setNeedPermissions(true);
    }
  }, [isOpen]);

  React.useLayoutEffect(() => {
    if (button.current && tooltip.current && (devices || updateRequired)) {
      computeMenuPosition(button.current, tooltip.current).then(({ x, y }) => {
        if (tooltip.current) {
          Object.assign(tooltip.current.style, {
            left: `${x}px`,
            top: `${y}px`,
          });
        }
      });
    }
    setUpdateRequired(false);
  }, [button, tooltip, devices, updateRequired]);

  const handleClickOutside = React.useCallback(
    (event: MouseEvent) => {
      if (!tooltip.current) {
        return;
      }
      if (event.target === button.current) {
        return;
      }
      if (isOpen && wasClickOutside(tooltip.current, event)) {
        setIsOpen(false);
      }
    },
    [isOpen, tooltip, button]
  );

  React.useEffect(() => {
    document.addEventListener<"click">("click", handleClickOutside);
    window.addEventListener<"resize">("resize", () => setUpdateRequired(true));
    return () => {
      document.removeEventListener<"click">("click", handleClickOutside);
      window.removeEventListener<"resize">("resize", () =>
        setUpdateRequired(true)
      );
    };
  }, [handleClickOutside, setUpdateRequired]);

  return (
    <>
      <button
        className="h-full text-[#4F4738] px-2 grid place-items-center"
        aria-pressed={isOpen}
        {...props}
        onClick={() => setIsOpen(!isOpen)}
        ref={button}
      >
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M5.21967 8.21967C5.51256 7.92678 5.98744 7.92678 6.28033 8.21967L10 11.9393L13.7197 8.21967C14.0126 7.92678 14.4874 7.92678 14.7803 8.21967C15.0732 8.51256 15.0732 8.98744 14.7803 9.28033L10.5303 13.5303C10.3897 13.671 10.1989 13.75 10 13.75C9.80109 13.75 9.61032 13.671 9.46967 13.5303L5.21967 9.28033C4.92678 8.98744 4.92678 8.51256 5.21967 8.21967Z"
            fill="#0F172A"
          />
        </svg>
      </button>
      {/** only render when enabled in order to make sure that the permissions are requested only if the menu is enabled */}
      {!props.disabled && (
        <div
          className="absolute w-max z-10 -top-24 -left-44 min-w-40 p-2 mb-1 whitespace-nowrap bg-[#FDFBF8] shadow-lg rounded-xl text-[#4F4738]"
          ref={tooltip}
          style={{ visibility: isOpen ? "visible" : "hidden" }}
        >
          {kind ? (
            <MediaDeviceSelect
              initialSelection={initialSelection}
              onActiveDeviceChange={(deviceId) =>
                handleActiveDeviceChange(kind, deviceId)
              }
              onDeviceListChange={setDevices}
              kind={kind}
              track={tracks?.[kind]}
              requestPermissions={needPermissions}
            />
          ) : (
            <>
              <div className="lk-device-menu-heading">Audio inputs</div>
              <MediaDeviceSelect
                kind="audioinput"
                onActiveDeviceChange={(deviceId) =>
                  handleActiveDeviceChange("audioinput", deviceId)
                }
                onDeviceListChange={setDevices}
                track={tracks?.audioinput}
                requestPermissions={needPermissions}
              />
              <div className="lk-device-menu-heading">Video inputs</div>
              <MediaDeviceSelect
                kind="videoinput"
                onActiveDeviceChange={(deviceId) =>
                  handleActiveDeviceChange("videoinput", deviceId)
                }
                onDeviceListChange={setDevices}
                track={tracks?.videoinput}
                requestPermissions={needPermissions}
              />
            </>
          )}
        </div>
      )}
    </>
  );
}
