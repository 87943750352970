import * as React from "react";
import type { ChatMessage, ChatOptions } from "@livekit/components-core";
import {
  ChatToggle,
  MessageFormatter,
  useChat,
  useMaybeLayoutContext,
} from "@livekit/components-react";
import { cloneSingleChild } from "../utils/utils";
import { ChatEntry } from "./ChatEntry";
import { db, Message } from "@/config/instantConfig";
import { ReceivedChatMessage } from "@livekit/components-core";
import { id, tx, User } from "@instantdb/react";

/** @public */
export interface ChatProps
  extends React.HTMLAttributes<HTMLDivElement>,
    ChatOptions {
  user: User;
  messageFormatter?: MessageFormatter;
}

/**
 * The Chat component adds a basis chat functionality to the LiveKit room. The messages are distributed to all participants
 * in the room. Only users who are in the room at the time of dispatch will receive the message.
 *
 * @example
 * ```tsx
 * <LiveKitRoom>
 *   <Chat />
 * </LiveKitRoom>
 * ```
 * @public
 */
export function Chat({
  user,
  messageFormatter,
  messageDecoder,
  messageEncoder,
  channelTopic,
  ...props
}: ChatProps) {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const ulRef = React.useRef<HTMLUListElement>(null);

  const chatOptions: ChatOptions = React.useMemo(() => {
    return { messageDecoder, messageEncoder, channelTopic };
  }, [messageDecoder, messageEncoder, channelTopic]);

  const { isSending } = useChat(chatOptions);

  const { data: chatMessages } = db.useQuery({ messages: {} });

  const layoutContext = useMaybeLayoutContext();

  const lastReadMsgAt = React.useRef<ChatMessage["timestamp"]>(0);

  async function handleSubmit(event: React.FormEvent) {
    event.preventDefault();
    if (inputRef.current && inputRef.current.value.trim() !== "") {
      await db.transact(
        tx.messages[id()].update({
          message: inputRef.current.value,
          from: {
            name: user.email,
            identity: user.id,
          },
          createdAt: Date.now(),
        })
      );
      inputRef.current.value = "";
      inputRef.current.focus();
    }
  }

  React.useEffect(() => {
    if (ulRef) {
      ulRef.current?.scrollTo({ top: ulRef.current.scrollHeight });
    }
  }, [ulRef, chatMessages]);

  React.useEffect(() => {
    if (!layoutContext || chatMessages?.messages.length === 0) {
      return;
    }

    if (
      layoutContext.widget.state?.showChat &&
      chatMessages &&
      chatMessages.messages.length > 0 &&
      lastReadMsgAt.current !==
        chatMessages.messages[chatMessages.messages.length - 1]?.createdAt
    ) {
      lastReadMsgAt.current =
        chatMessages.messages[chatMessages.messages.length - 1]?.createdAt;
      return;
    }

    const unreadMessageCount = chatMessages?.messages.filter(
      (msg) => !lastReadMsgAt.current || msg.createdAt > lastReadMsgAt.current
    ).length;

    const { widget } = layoutContext;
    if (
      unreadMessageCount !== undefined &&
      unreadMessageCount > 0 &&
      widget.state?.unreadMessages !== unreadMessageCount
    ) {
      widget.dispatch?.({ msg: "unread_msg", count: unreadMessageCount });
    }
  }, [chatMessages, layoutContext?.widget]);

  return (
    <div
      {...props}
      className="flex flex-col bg-[#F4EEE2] text-[#050301] h-full"
    >
      <div className="lk-chat-header">
        Messages
        <ChatToggle className="lk-close-button group">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={16}
            height={16}
            viewBox="0 0 24 24"
            className="group-hover:text-white text-[#050301]"
          >
            <path
              fill="currentColor"
              d="M4.99 3.99a1 1 0 0 0-.697 1.717L10.586 12l-6.293 6.293a1 1 0 1 0 1.414 1.414L12 13.414l6.293 6.293a1 1 0 1 0 1.414-1.414L13.414 12l6.293-6.293a1 1 0 0 0-.727-1.717 1 1 0 0 0-.687.303L12 10.586 5.707 4.293a1 1 0 0 0-.717-.303z"
            />
          </svg>
        </ChatToggle>
      </div>

      <ul className="lk-list lk-chat-messages h-full" ref={ulRef}>
        {props.children
          ? chatMessages?.messages.map((msg, idx) =>
              cloneSingleChild(props.children, {
                entry: msg,
                key: msg.id ?? idx,
                messageFormatter,
              })
            )
          : chatMessages?.messages.map((msg, idx, allMsg) => {
              const hideName = idx >= 1 && allMsg[idx - 1].from === msg.from;
              // If the time delta between two messages is bigger than 60s show timestamp.
              const hideTimestamp =
                idx >= 1 && msg.createdAt - allMsg[idx - 1].createdAt < 60_000;

              return (
                <ChatEntry
                  key={msg.id ?? idx}
                  hideName={hideName}
                  hideTimestamp={hideName === false ? false : hideTimestamp} // If we show the name always show the timestamp as well.
                  entry={msg as Message}
                  messageFormatter={messageFormatter}
                />
              );
            })}
      </ul>
      <form className="lk-chat-form" onSubmit={handleSubmit}>
        <input
          className="px-4 outline-none bg-[#F4EEE2]"
          disabled={isSending}
          ref={inputRef}
          type="text"
          placeholder="Enter a message..."
          onInput={(ev) => ev.stopPropagation()}
          onKeyDown={(ev) => ev.stopPropagation()}
          onKeyUp={(ev) => ev.stopPropagation()}
        />
        <button
          type="submit"
          className="bg-[#8C7E67] text-[#FFFFFF] h-10 w-max px-4 rounded-xl"
          disabled={isSending}
        >
          Send
        </button>
      </form>
    </div>
  );
}
