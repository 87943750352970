import { Message } from "@/config/instantConfig";
import type { ReceivedChatMessage } from "@livekit/components-core";
import { tokenize, createDefaultGrammar } from "@livekit/components-core";
import * as React from "react";

/** @public */
export type MessageFormatter = (message: string) => React.ReactNode;

/**
 * ChatEntry composes the HTML div element under the hood, so you can pass all its props.
 * These are the props specific to the ChatEntry component:
 * @public
 */
export interface ChatEntryProps extends React.HTMLAttributes<HTMLLIElement> {
  /** The chat massage object to display. */
  entry: Message;
  /** Hide sender name. Useful when displaying multiple consecutive chat messages from the same person. */
  hideName?: boolean;
  /** Hide message timestamp. */
  hideTimestamp?: boolean;
  /** An optional formatter for the message body. */
  messageFormatter?: MessageFormatter;
}

/**
 * The `ChatEntry` component holds and displays one chat message.
 *
 * @example
 * ```tsx
 * <Chat>
 *   <ChatEntry />
 * </Chat>
 * ```
 * @see `Chat`
 * @public
 */
export const ChatEntry: (
  props: ChatEntryProps & React.RefAttributes<HTMLLIElement>
) => React.ReactNode = /* @__PURE__ */ React.forwardRef<
  HTMLLIElement,
  ChatEntryProps
>(function ChatEntry(
  {
    entry,
    hideName = false,
    hideTimestamp = false,
    messageFormatter,
    ...props
  }: ChatEntryProps,
  ref
) {
  const formattedMessage = React.useMemo(() => {
    return messageFormatter ? messageFormatter(entry.message) : entry.message;
  }, [entry.message, messageFormatter]);
  const hasBeenEdited = !!entry.editedAt;
  const time = new Date(entry.createdAt);
  const locale = navigator ? navigator.language : "en-US";

  return (
    <li
      ref={ref}
      className="lk-chat-entry"
      title={time.toLocaleTimeString(locale, { timeStyle: "full" })}
      data-lk-message-origin={entry.from?.isLocal ? "local" : "remote"}
      {...props}
    >
      {(!hideTimestamp || !hideName || hasBeenEdited) && (
        <span className="lk-meta-data">
          {!hideName && (
            <strong className="lk-participant-name text-[#454942]">
              {entry.from?.name ?? entry.from?.identity}
            </strong>
          )}

          {(!hideTimestamp || hasBeenEdited) && (
            <span className="lk-timestamp text-[#454942]">
              {hasBeenEdited && "edited "}
              {time.toLocaleTimeString(locale, { timeStyle: "short" })}
            </span>
          )}
        </span>
      )}

      <span
        className={`break-words w-fit rounded-2xl py-1 px-3 ${
          entry.from?.isLocal ? "bg-[#62AAC2]" : "bg-[#EADCC5]"
        }`}
      >
        {formattedMessage}
      </span>
    </li>
  );
});

/** @public */
export function formatChatMessageLinks(message: string): React.ReactNode {
  return tokenize(message, createDefaultGrammar()).map((tok, i) => {
    if (typeof tok === `string`) {
      return tok;
    } else {
      const content = tok.content.toString();
      const href =
        tok.type === `url`
          ? /^http(s?):\/\//.test(content)
            ? content
            : `https://${content}`
          : `mailto:${content}`;
      return (
        <a
          className="lk-chat-link"
          key={i}
          href={href}
          target="_blank"
          rel="noreferrer"
        >
          {content}
        </a>
      );
    }
  });
}
