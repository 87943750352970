import { init } from "@instantdb/react";
import { Participant } from "livekit-client";

const APP_ID = "f90f7f5a-d4e5-47e4-b671-8cafcc6c9b78";

export type Message = {
  id: string;
  message: string;
  from: Participant;
  createdAt: number;
  editedAt?: number;
};

type MyAppSchema = {
  messages: Message;
};

export type RoomSchema = {
  videoChat: {
    presence: {
      id: string;
      email: string;
      isSpeaking: boolean;
    };
    topics: {
      signaling: {
        description?: RTCSessionDescriptionInit | null;
        candidate?: RTCIceCandidate | null;
        to: string;
        from: string;
      };
    };
  };
  main: {
    presence: {
      id: string;
      email: string;
      currentRoom: string;
    };
  };
};

export const db = init<MyAppSchema, RoomSchema>({
  appId: APP_ID,
  devtool: false,
});
