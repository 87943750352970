"use client";

import { db } from "@/config/instantConfig";
import { useEffect, useState } from "react";
import styles from "../styles/Home.module.css";
import { Login } from "./components/Auth";
import CommsRoomWrapper from "./components/CommsRoomWrapper";

// Static list of rooms with predefined encryption keys
const rooms = [
  {
    name: "Room 1",
    id: "11111111-1111-1111-1111-111111111111",
    encryptionKey: "GeneralRoomKey1234567890", // Replace with secure keys
  },
  {
    name: "Room 2",
    id: "22222222-2222-2222-2222-222222222222",
    encryptionKey: "DevelopersRoomKey1234567890",
  },
  {
    name: "Room 3",
    id: "33333333-3333-3333-3333-333333333333",
    encryptionKey: "DesignRoomKey1234567890",
  },
];

export default function Page() {
  const [roomName, setRoomName] = useState<string | null>(null);
  const [encryptionKey, setEncryptionKey] = useState<string | null>(null);

  const { isLoading, user, error } = db.useAuth();
  const mainRoom = db.room("main", "main");
  const { peers: mainPeers, publishPresence: mainPublishPresence } =
    mainRoom.usePresence();

  useEffect(() => {
    if (user && roomName) {
      mainPublishPresence({
        id: user.id,
        email: user.email,
        currentRoom: roomName,
      });
    }
  }, [user, roomName, mainPublishPresence]);

  const joinRoom = (roomId: string, encryptionKey: string) => {
    setRoomName(null);
    setEncryptionKey(null);
    setTimeout(() => {
      setRoomName(roomId);
      setEncryptionKey(encryptionKey);
    }, 100);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>Uh oh! {error.message}</div>;
  }
  if (user) {
    return (
      <span className="flex h-full">
        <div className={styles.tabContent}>
          <h1>Hello {user.email}!</h1>
          <button onClick={() => db.auth.signOut()}>Sign out</button>
          <p style={{ margin: 0 }}>Select a room to join:</p>
          <ul className="room-list" style={{ listStyle: "none", padding: 0 }}>
            {rooms.map((room) => (
              <li key={room.id} style={{ margin: "0.5rem 0" }}>
                <button
                  className="lk-button"
                  style={{
                    width: "100%",
                    padding: "0.75rem",
                    fontSize: "1rem",
                  }}
                  onClick={() => joinRoom(room.id, room.encryptionKey)}
                >
                  Join {room.name}
                </button>
                <ul>
                  {Object.values(mainPeers)
                    .filter((peer) => peer.currentRoom === room.id)
                    .map((peer) => (
                      <li key={peer.id}>{peer.email}</li>
                    ))}
                </ul>
              </li>
            ))}
          </ul>
        </div>
        {roomName && encryptionKey && (
          <CommsRoomWrapper
            user={user}
            roomName={roomName}
            encryptionKey={encryptionKey}
            setRoomName={setRoomName}
            setEncryptionKey={setEncryptionKey}
          />
        )}
      </span>
    );
  }
  return <Login />;
}
