"use client";

import { VideoCall } from "@/app/components/VideoCall";
import { decodePassphrase } from "@/lib/client-utils";
import { DebugMode } from "@/lib/Debug";
import { SettingsMenu } from "@/lib/SettingsMenu";
import { ConnectionDetails } from "@/lib/types";
import { User } from "@instantdb/react";
import {
  formatChatMessageLinks,
  LiveKitRoom,
  LocalUserChoices,
} from "@livekit/components-react";
import {
  DeviceUnsupportedError,
  ExternalE2EEKeyProvider,
  Room,
  RoomConnectOptions,
  RoomOptions,
  VideoPresets,
} from "livekit-client";
import * as React from "react";

const SHOW_SETTINGS_MENU = process.env.NEXT_PUBLIC_SHOW_SETTINGS_MENU == "true";

export function CommsRoom(props: {
  user: User;
  userChoices: LocalUserChoices;
  connectionDetails: ConnectionDetails;
  encryptionKey: string;
  setRoomName: React.Dispatch<React.SetStateAction<string | null>>;
  setEncryptionKey: React.Dispatch<React.SetStateAction<string | null>>;
}) {
  const e2eePassphrase = decodePassphrase(props.encryptionKey);
  if (!e2eePassphrase) {
    throw new Error("E2EE passphrase is required");
  }

  const worker = new Worker(
    new URL("livekit-client/e2ee-worker", import.meta.url)
  );
  const keyProvider = new ExternalE2EEKeyProvider();

  const roomOptions = React.useMemo((): RoomOptions => {
    return {
      videoCaptureDefaults: {
        deviceId: props.userChoices.videoDeviceId ?? undefined,
        resolution: VideoPresets.h2160,
      },
      publishDefaults: {
        dtx: false,
        videoSimulcastLayers: [VideoPresets.h1080, VideoPresets.h720],
        red: false,
        videoCodec: undefined,
      },
      audioCaptureDefaults: {
        deviceId: props.userChoices.audioDeviceId ?? undefined,
      },
      adaptiveStream: { pixelDensity: "screen" },
      dynacast: true,
      e2ee: {
        keyProvider,
        worker,
      },
    };
  }, [props.userChoices]);

  const room = React.useMemo(() => new Room(roomOptions), []);

  React.useEffect(() => {
    keyProvider.setKey(e2eePassphrase);
    room.setE2EEEnabled(true).catch((e) => {
      if (e instanceof DeviceUnsupportedError) {
        alert(
          `Your browser does not support encrypted meetings. Please update it to the latest version and try again.`
        );
        console.error(e);
      }
    });
  }, []);

  const connectOptions = React.useMemo((): RoomConnectOptions => {
    return {
      autoSubscribe: true,
    };
  }, []);

  const handleOnLeave = React.useCallback(() => {
    props.setRoomName(null);
    props.setEncryptionKey(null);
  }, [props]);

  return (
    <>
      <LiveKitRoom
        room={room}
        token={props.connectionDetails.participantToken}
        serverUrl={props.connectionDetails.serverUrl}
        connectOptions={connectOptions}
        video={props.userChoices.videoEnabled}
        audio={props.userChoices.audioEnabled}
        onDisconnected={handleOnLeave}
        className="bg-[#FDFBF8]"
      >
        <VideoCall
          user={props.user}
          chatMessageFormatter={formatChatMessageLinks}
          SettingsComponent={SHOW_SETTINGS_MENU ? SettingsMenu : undefined}
        />
        <DebugMode />
      </LiveKitRoom>
    </>
  );
}
