"use client";

import * as React from "react";
import { ConnectionDetails } from "@/lib/types";
import { CommsRoom } from "./CommsRoom";
import { LocalUserChoices } from "@livekit/components-react";
import { User } from "@instantdb/react";

export default function CommsRoomWrapper(props: {
  user: User;
  roomName: string;
  encryptionKey: string;
  setRoomName: React.Dispatch<React.SetStateAction<string | null>>;
  setEncryptionKey: React.Dispatch<React.SetStateAction<string | null>>;
}) {
  const [connectionDetails, setConnectionDetails] = React.useState<
    ConnectionDetails | undefined
  >(undefined);
  const [userChoices, setUserChoices] = React.useState<
    LocalUserChoices | undefined
  >(undefined);

  React.useEffect(() => {
    const fetchUserDevices = async () => {
      try {
        // Request permission to access media devices
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: true,
        });

        // Get the settings from the media stream tracks
        const audioTrack = stream.getAudioTracks()[0];
        const videoTrack = stream.getVideoTracks()[0];

        const audioDeviceId = audioTrack.getSettings().deviceId || "";
        const videoDeviceId = videoTrack.getSettings().deviceId || "";

        // Stop the tracks after getting the device IDs to free up resources
        audioTrack.stop();
        videoTrack.stop();

        setUserChoices({
          username: props.user.email,
          videoEnabled: false,
          audioEnabled: true,
          audioDeviceId,
          videoDeviceId,
        });

        // Optionally, store the device IDs for persistence
        localStorage.setItem("audioDeviceId", audioDeviceId);
        localStorage.setItem("videoDeviceId", videoDeviceId);
      } catch (error) {
        console.error("Error accessing media devices:", error);
        // Fallback to empty device IDs if access is denied or fails
        setUserChoices({
          username: props.user.email,
          videoEnabled: false,
          audioEnabled: true,
          audioDeviceId: "",
          videoDeviceId: "",
        });
      }
    };

    const joinRoom = async () => {
      const url = `/api/connection-details?roomName=${props.roomName}&participantName=${props.user.email}&participantId=${props.user.id}`;
      try {
        const connectionDetailsResp = await fetch(url.toString());
        const connectionDetailsData = await connectionDetailsResp.json();
        setConnectionDetails(connectionDetailsData);
      } catch (error) {
        console.error("Failed to fetch connection details:", error);
      }
    };

    fetchUserDevices();
    joinRoom();
  }, [props.roomName, props.user]);

  return (
    <main data-lk-theme="default" style={{ height: "100%", width: "100%" }}>
      {connectionDetails === undefined || userChoices === undefined ? (
        <div style={{ display: "grid", placeItems: "center", height: "100%" }}>
          <p className="text-black">Joining room...</p>
        </div>
      ) : (
        <CommsRoom
          user={props.user}
          connectionDetails={connectionDetails}
          userChoices={userChoices}
          encryptionKey={props.encryptionKey}
          setRoomName={props.setRoomName}
          setEncryptionKey={props.setEncryptionKey}
        />
      )}
    </main>
  );
}
